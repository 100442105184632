import React, { useState, useEffect } from "react";
import HeaderLogo from "./HeaderLogo";
import { observer, useTranslation } from "app";
import {
  Navbar,
  NavbarItem,
  ItemTypes,
  DesktopAlign,
  MobileMenuType,
  DropdownItem,
  MenuItem,
  Placement,
} from "@carfax/web-ui/components/Navbar";
import { languageConfig } from "i18n/language-config";
import { Icons } from "@carfax/web-ui/elements/Icon";
import { navigationUtil } from "./utils/navigationUtil";
import { Routes } from "utils/config/routes";
import { useRoutes } from "domain/Routing/hooks/useRoutes";
import { linkUtil } from "utils/i18n/link-util";
import { useStores } from "hooks";
import { logoutUtil } from "domain/User/utils/logoutUtil";
import routerService from "domain/Routing/utils/router-service";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { MegaMenuColumn } from "@carfax/web-ui/components/Navbar/MegaMenu";
import { useAuth0 } from "@auth0/auth0-react";
import { getSocialLoginRedirectUri } from "../../../domain/User/utils/socialLoginUtils";

const DesktopSearch = dynamic(() => import("./HeaderSearch/DesktopSearch/DesktopSearch"), {
  ssr: false,
});

const MobileSearch = dynamic(() => import("./HeaderSearch/MobileSearch/MobileSearch"), {
  ssr: false,
});

export interface Props {
  hideNavInHeader?: boolean;
  showOnlyLoginInNavbar?: boolean;
  loginRedirect?: string;
  languageOptions: DropdownItem[];
}

const Header: React.FC<Props> = observer(
  ({ hideNavInHeader = false, showOnlyLoginInNavbar = false, loginRedirect, languageOptions = [] }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const { t, i18n } = useTranslation(["common"]);
    const { userStore } = useStores();
    const { logout } = useAuth0();

    const language = languageConfig.mapLocaleToLanguageShort(i18n.language);
    const routes = useRoutes();
    const router = useRouter();

    const handleOnClick = (e, link) => {
      if (link) {
        e.preventDefault();
        router.push(link);
      }
    };

    const handleInSeparateBookmark = (e, link) => {
      e.preventDefault();

      if (link) {
        const newWindow = window.open(link, "_blank", "noopener,noreferrer");

        if (newWindow) {
          newWindow.opener = null;
        }
      }
    };

    const logoutHandler = async () => {
      await logoutUtil.logout();
      if (userStore.isSocialLogin) {
        await logout({ logoutParams: { returnTo: getSocialLoginRedirectUri(Routes.HOME) } });
      }
      routerService.routeTo(Routes.HOME);
    };

    useEffect(() => {
      setIsAuthenticated(userStore.isAuthenticated);
    }, [userStore.isAuthenticated]);

    const megaMenuColumns: MegaMenuColumn[] = [
      {
        items: [
          {
            name: t("header.menu.supportCenter.title"),
            link: linkUtil.i18nLinkWithLanguage(routes.faq, language),
            onClick: handleInSeparateBookmark,
            icon: Icons.supportCenterIcon,
            testSelector: "support-center-link",
            description: t("header.menu.supportCenter.description"),
            isActive: navigationUtil.isCurrentPage(routes.faq),
          },
          {
            name: "Blog",
            link: linkUtil.i18nLinkWithLanguage(routes.blog, language),
            onClick: handleOnClick,
            icon: Icons.blogIcon,
            testSelector: "menu-link-internal",
            description: t("header.menu.blog.description"),
            isActive: navigationUtil.isParentOfCurrentPage(routes.blog),
          },
        ],
      },
      {
        title: t("header.menu.categories.tools"),
        items: [
          {
            name: t("header.menu.freeVinDecoder"),
            link: linkUtil.i18nLinkWithLanguage(routes.freeVinDecoder, language),
            onClick: handleOnClick,
            testSelector: "vin-decoder-link",
            isActive: navigationUtil.isCurrentPage(routes.freeVinDecoder),
          },

          {
            name: t("header.menu.bmwVinDecoder"),
            link: linkUtil.i18nLinkWithLanguage(routes.bmwVinDecoder, language),
            onClick: handleOnClick,
            testSelector: "bmw-vin-decoder-link",
            isActive: navigationUtil.isCurrentPage(routes.bmwVinDecoder),
          },
        ],
      },
      {
        title: t("header.menu.categories.carChecks"),
        items: [
          {
            name: t("header.menu.mileageCheck"),
            link: linkUtil.i18nLinkWithLanguage(routes.mileageCheck, language),
            onClick: handleOnClick,
            testSelector: "mileage-check-link",
            isActive: navigationUtil.isCurrentPage(routes.mileageCheck),
          },
          {
            name: t("header.menu.serviceHistory"),
            link: linkUtil.i18nLinkWithLanguage(routes.carServiceHistory, language),
            onClick: handleOnClick,
            testSelector: "service-history-link",
            isActive: navigationUtil.isCurrentPage(routes.carServiceHistory),
          },
          {
            name: t("header.menu.importCheck"),
            link: linkUtil.i18nLinkWithLanguage(routes.freeImportCheck, language),
            onClick: handleOnClick,
            testSelector: "import-check-link",
            isActive: navigationUtil.isCurrentPage(routes.freeImportCheck),
          },
        ],
      },
    ];

    const accountDropdownItems: DropdownItem[] = [
      {
        label: t("header.menu.accountDashboard"),
        link: linkUtil.i18nLinkWithLanguage(routes.account, language),
        icon: Icons.dashboard,
        testSelector: "dashboard-link",
      },
      {
        label: t("header.menu.accountReports"),
        link: linkUtil.i18nLinkWithLanguage(routes.accountReports, language),
        icon: Icons.reports,
        testSelector: "reports-link",
      },
      {
        label: t("header.menu.accountSettings"),
        link: linkUtil.i18nLinkWithLanguage(routes.accountSettings, language),
        icon: Icons.settings,
        testSelector: "settings-link",
      },
      {
        label: t("header.menu.logout"),
        icon: Icons.logout,
        click: (e) => {
          e.preventDefault();
          logoutHandler();
        },
        testSelector: "logout-link",
      },
    ];

    const AccountInfo: React.FC = () => {
      return (
        <MenuItem icon={Icons.account}>
          <div>{userStore.email}</div>
        </MenuItem>
      );
    };

    const renderDesktopSearch = ({ onClose = () => null }) => {
      return <DesktopSearch onClose={onClose} />;
    };

    const renderFullNavbar = () => {
      return (
        userStore && (
          <Navbar logo={<HeaderLogo />} hideNavInHeader={hideNavInHeader}>
            <NavbarItem
              title={t("header.menu.home")}
              key="home-navitem"
              onClick={handleOnClick}
              link={linkUtil.i18nLinkWithLanguage(Routes.HOME, language)}
              isActive={navigationUtil.isCurrentPage(Routes.HOME)}
              options={{
                type: ItemTypes.Button,
                desktopAlign: DesktopAlign.Left,
                mobileMenu: MobileMenuType.Main,
                hideOnDesktop: true,
              }}
            />
            <NavbarItem
              title={t("header.menu.ourService")}
              key="our-service-navitem"
              onClick={handleOnClick}
              link={linkUtil.i18nLinkWithLanguage(routes.sampleReport, language)}
              isActive={navigationUtil.isParentOfCurrentPage(routes.sampleReport)}
              options={{ type: ItemTypes.Button, desktopAlign: DesktopAlign.Left, mobileMenu: MobileMenuType.Main }}
            />
            <NavbarItem
              title={t("header.menu.pricing")}
              key="pricing-navitem"
              onClick={handleOnClick}
              link={linkUtil.i18nLinkWithLanguage(routes.pricing, language)}
              isActive={navigationUtil.isParentOfCurrentPage(routes.pricing)}
              options={{ type: ItemTypes.Button, desktopAlign: DesktopAlign.Left, mobileMenu: MobileMenuType.Main }}
              testSelector="pricing-link"
            />
            <NavbarItem
              title={t("header.menu.business")}
              key="business-navitem"
              onClick={handleOnClick}
              link={linkUtil.i18nLinkWithLanguage(routes.business, language)}
              isActive={navigationUtil.isParentOfCurrentPage(routes.business)}
              options={{ type: ItemTypes.Button, desktopAlign: DesktopAlign.Left, mobileMenu: MobileMenuType.Main }}
            />
            <NavbarItem
              title={t("header.menu.resources")}
              key="resources-navitem"
              onClick={handleOnClick}
              options={{ type: ItemTypes.MegaMenu, desktopAlign: DesktopAlign.Left, mobileMenu: MobileMenuType.Main }}
              megaMenuColumns={megaMenuColumns}
              testSelector="resources-navbar-menu"
            />
            <NavbarItem
              title={t("header.search.label")}
              key="search-navitem"
              onClick={handleOnClick}
              testSelector="nav-search-button"
              icon={Icons.searchHeaderTopBar}
              options={{
                type: ItemTypes.Search,
                desktopAlign: DesktopAlign.Right,
                mobileMenu: MobileMenuType.Own,
              }}
              customElement={{ sideMenu: <MobileSearch />, render: renderDesktopSearch }}
            />
            <NavbarItem
              title={t("dropdown.language")}
              key="language-dropdown-navitem"
              onClick={handleOnClick}
              activeSubItem={t(`dropdown.${language}`)}
              options={{
                type: ItemTypes.Dropdown,
                desktopAlign: DesktopAlign.Right,
                mobileMenu: MobileMenuType.Main,
                dropdownAlignment: Placement.bottomStart,
              }}
              dropdownItems={languageOptions}
            />
            {!isAuthenticated ? (
              <NavbarItem
                title={t("header.menu.login")}
                key="login-navitem"
                onClick={handleOnClick}
                link={linkUtil.i18nLinkWithLanguage(routes.accountLogin, language)}
                options={{ desktopAlign: DesktopAlign.Right, type: ItemTypes.Button, mobileMenu: MobileMenuType.None }}
                testSelector="login-link-redirect"
              />
            ) : (
              <NavbarItem
                title={t("header.menu.myCarfax")}
                key="account-dropdown-navitem"
                onClick={handleOnClick}
                icon={Icons.accountOutline}
                options={{ desktopAlign: DesktopAlign.Right, type: ItemTypes.Dropdown, mobileMenu: MobileMenuType.Own }}
                dropdownItems={accountDropdownItems}
                customElement={{ sideMenu: <AccountInfo />, dropdownMenu: <AccountInfo /> }}
                testSelector="account-area-toggle"
              />
            )}
          </Navbar>
        )
      );
    };

    const renderonlyLoginNavbar = () => {
      return (
        userStore && (
          <Navbar logo={<HeaderLogo />} renderOnlyLogin={true} hideNavInHeader={hideNavInHeader}>
            <NavbarItem
              title={t("dropdown.language")}
              key="language-dropdown-navitem"
              onClick={handleOnClick}
              activeSubItem={t(`dropdown.${language}`)}
              options={{
                type: ItemTypes.Dropdown,
                desktopAlign: DesktopAlign.Right,
                mobileMenu: MobileMenuType.Main,
                dropdownAlignment: Placement.bottomStart,
              }}
              dropdownItems={languageOptions}
            />
            {!isAuthenticated ? (
              <NavbarItem
                title={t("header.menu.login")}
                key="login-navitem"
                onClick={handleOnClick}
                link={
                  loginRedirect
                    ? Routes.accountLoginWithRedirect(loginRedirect)
                    : linkUtil.i18nLinkWithLanguage(routes.accountLogin, language)
                }
                options={{ desktopAlign: DesktopAlign.Right, type: ItemTypes.Button, mobileMenu: MobileMenuType.None }}
                testSelector="login-link-redirect"
              />
            ) : (
              <NavbarItem
                title={t("header.menu.myCarfax")}
                key="account-dropdown-navitem"
                onClick={handleOnClick}
                icon={Icons.accountOutline}
                options={{ desktopAlign: DesktopAlign.Right, type: ItemTypes.Dropdown, mobileMenu: MobileMenuType.Own }}
                dropdownItems={accountDropdownItems}
                customElement={{ sideMenu: <AccountInfo />, dropdownMenu: <AccountInfo /> }}
                testSelector="account-area-toggle"
              />
            )}
          </Navbar>
        )
      );
    };

    return showOnlyLoginInNavbar ? renderonlyLoginNavbar() : renderFullNavbar();
  }
);

export default Header;
